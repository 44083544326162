define('effectus-app/routes/patientlog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var transitionConfirm = true;

  exports.default = Ember.Route.extend({
    progr: Ember.inject.service('current-program'),
    exportCsvService: Ember.inject.service('export-csv'),

    beforeModel() {

      if (this.get('progr').getPhysician() == null) {
        if (this.get('progr').getRole() == 'admin') {
          this.transitionTo('patientlogselect');
        }
      }

      if (this.get('progr').getProgram() == null || this.get('progr').getProgram() === 0) {
        this.transitionTo('index');
      }

      if (this.get('progr').getRole() == 'recruiter') {
        this.transitionTo('physicians');
      }

      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },

    model() {

      //for hcp
      let exportQuery = this.store.query('patientlog', { study_id: this.get('progr').getProgram(), phys_id: this.get('progr').getPhysician() });
      if (this.get('progr').get('personIsAdmin')) //if admin, export all HCP - patients
        {
          //for admin
          exportQuery = this.store.query('patientlog', { study_id: this.get('progr').getProgram() });
        }

      return Ember.RSVP.hash({
        patientlog: this.store.query('patientlog', {
          study_id: this.get('progr').getProgram(),
          phys_id: this.get('progr').getPhysician(),
          phys_em: this.get('progr').getEmail()
        }),

        program: this.store.query('program', {
          study_id: this.get('progr').getProgram(),
          phys_em: this.get('progr').getEmail()
        }),

        question: this.store.query('patientlogquestion', { study_id: this.get('progr').getProgram() }),

        deidPatientsForAllHcps: exportQuery // used to export patient log data 
      });
    },

    setupController(controller, model) {
      controller.set('patientlog', model.patientlog);
      controller.set('program', model.program);
      controller.set('question', model.question);
      controller.set('deidPatientsForAllHcps', model.deidPatientsForAllHcps);
    },

    actions: {
      willTransition: function (transition) {
        if (transitionConfirm && transition.targetName !== 'patientlog') {
          //existing patient
          if (this.controller.get('editPatientID') > 0) {
            let patient = this.controller.get('patientlog').findBy('id', this.controller.get('editPatientID'));
            let changes = Object.keys(patient.changedAttributes()).length;
            if (changes > 0) {
              //prompt question
              if (!confirm("You have unsaved changes. Are you sure you want to abandon changes?")) {
                transition.abort();
              } else {
                patient.rollbackAttributes();
                resetEditMode(patient, this.controller);
                this.refresh();
              }
            } else {
              patient.rollbackAttributes();
              resetEditMode(patient, this.controller);
              this.refresh();
            }
          } else if (this.controller.get('isAddingNewPatient')) {
            // new patient
            if (!this.controller.get('isNewPatientNoData')) {
              //messy code need to refactor - similar to patient tracking
              if (!confirm("You have unsaved changes. Are you sure you want to abandon changes?")) {
                transition.abort();
              } else {
                //temp way to do this
                resetAddMode(this.controller);
                // first need to change patientlog for new patient code similar to new patient tracking patient
                // for now leave commented out
                /* let patient = this.controller.get('newpatient'); 
                patient.rollbackAttributes();
                resetAddMode(this.controller); */
                this.refresh();
              }
            } else {
              //blank form close it and clear data temp way to do this
              resetAddMode(this.controller);
              this.refresh();
            }
          }
        }
        transitionConfirm = true; //reset
      },

      deletePatient(patient) {
        let confirmation_txt = 'Are you sure you want to delete this patient?';
        if (patient.get('patient_deid_number')) {
          confirmation_txt = 'Are you sure you want to delete patient ' + patient.get('patient_deid_number') + '?';
        }
        let confirmation = confirm(confirmation_txt);
        if (confirmation) {
          patient.destroyRecord().then(() => {
            resetEditMode(patient, this.controller);
            transitionConfirm = false; //do not process willTransition       
            this.refresh();
          });
        }
      },

      addPatient() {
        this.controller.set('isProcessing', true);
        var patient = this.store.createRecord('patientlog');
        //refactor using binding
        patient.set('study_id', this.get('progr').getProgram());
        patient.set('site_id', this.get('progr').getSite());
        patient.set('physician_id', this.get('progr').getPhysician());
        patient.set('audit_modifiedby', this.get('progr').getEmail());
        patient.set('patient_deid_number', this.controller.get('newPatientID'));
        patient.set('date_consult', this.controller.get('newPatientDateConsult'));
        patient.set('patient_age', this.controller.get('newPatientAge'));
        let patient_answers = this.controller.get('newPatientAnswers');
        patient.set('patient_answers', JSON.stringify(patient_answers));
        let questions = this.get('controller').get('question');
        patient.set('answers_required', JSON.stringify(requiredAnswers(questions)));
        patient.save().then(() => {
          resetAddMode(this.controller);
          transitionConfirm = false; //do not process willTransition
          this.refresh();
        }).catch(res => {
          this.controller.set('isProcessing', false);
          let errorMessage = res.errors[0].detail;
          this.controller.set('errorAddMessage', errorMessage);
        });
      },

      updatePatient(patient) {
        this.controller.set('isProcessing', true);
        patient.set('study_id', this.get('progr').getProgram());
        patient.set('site_id', this.get('progr').getSite());
        patient.set('physician_id', this.get('progr').getPhysician());
        patient.set('audit_modifiedby', this.get('progr').getEmail());
        patient.save().then(() => {
          resetEditMode(patient, this.controller);
          transitionConfirm = false; //do not process willTransition
          this.refresh();
        }).catch(res => {
          this.controller.set('isProcessing', false);
          let errorMessage = res.errors[0].detail;
          this.controller.set('errorMessage', errorMessage);
        });
      },

      editPatient(patient) {
        editMode(patient, this.controller);
      },

      cancelEditPatient(patient) {
        if (this.controller.get('isNewPatientNoData')) {
          patient.destroyRecord().then(() => {
            transitionConfirm = false; //do not process willTransition        
            this.refresh();
          });
        }
        resetEditMode(patient, this.controller);
        patient.rollbackAttributes();
      },

      exportCsv() {
        let config = {
          filename: 'de-identified-patient-log.csv',
          columns: [{ heading: "Patient ID #", key: "patient_deid_number" }, { heading: "Study Site #", key: "site_code" }, { heading: "HCP First Name", key: "physician_fname" }, { heading: "HCP Last Name", key: "physician_lname" }, { heading: "Date of Identification", key: "date_consult" }, { heading: "Patient Age", key: "patient_age" }]
        };

        // Add headers for dynamic questions
        this.get('controller').get('question').forEach(function (question) {
          let questionColumn = {
            heading: question.get('question_value'),
            key: question.get('question_key')
          };
          config.columns.push(questionColumn);
        });
        config.columns.push({ heading: "Date Modified", key: "audit_modified" });

        let patientLogs = this.get('controller').get('deidPatientsForAllHcps');
        if (patientLogs) {
          let questions = this.get('controller').get('question');
          let data = patientLogs.map(patientLog => buildExportDataRowFromPatientLog(patientLog, config, questions));
          this.get('exportCsvService').export(data, config);
        }
      }
    }

  });


  //go to edit mode for both edit record and add new patient
  function editMode(patient, controller) {
    if (patient) {
      patient.set('isEditingPatient', true);
      patient.set('highlightPatient', 'cell-selected');
    }
    if (controller) {
      controller.set('isProcessing', false);
      controller.set('errorMessage', "");
      controller.set('editPatientID', patient.id); //set the current patient being edited
      controller.set('isNewPatientNoData', false);
    }
  }

  function resetEditMode(patient, controller) {
    if (patient) {
      patient.set('isEditingPatient', false);
      patient.set('highlightPatient', '');
    }
    if (controller) {
      controller.set('isProcessing', false);
      controller.set('isNewPatientNoData', false);
      controller.set('errorMessage', "");
      controller.set('editPatientID', 0); //reset patient being edited
    }
  }
  function resetAddMode(controller) {
    if (controller) {
      controller.set('isNewPatientNoData', false); //newly created patient with no data updated
      controller.set('newPatientQuestionKey', ''); //key for question being answered
      controller.set('newPatientAnswers', {}); //list of form answers for new patient form
      controller.set('requiredAnswers', {}); //list of form answers for new patient form
      controller.set('newPatientID', 0); //4-digit id of new patient
      controller.set('newPatientDateConsult', ''); //date consult of new patient
      controller.set('newPatientAge', ''); //age of new patient        
      controller.set('isAddingNewPatient', false);
      controller.set('isProcessing', false);
      controller.set('errorMessage', "");
      controller.set('errorAddMessage', '');
    }
  }
  /*** get list of required fields new patient validation ***/
  function requiredAnswers(questions) {
    let answersRequired = {};
    questions.forEach(function (question) {
      let key = question.get('question_key');
      let required = question.get('required');
      answersRequired[key] = required;
    });

    return answersRequired;
  }

  /**
   * Extract data from patientLog based on export config.
   * @param patientLog - model object
   * @param config - export configuration
   */
  function buildExportDataRowFromPatientLog(patientLog, config, questions) {
    let exportModel = Ember.Object.create();

    // Add any existing values from the root object (Physician Name, De-Id Number, etc.)
    for (let configColumn of config.columns) {
      if (patientLog.get(configColumn.key)) {
        exportModel.set(configColumn.key, patientLog.get(configColumn.key));
      }
    }

    // Completed dynamic questions in patientLog.compQuestions are stored in the form key*||*value,value
    // The values may have leading spaces and/or comma
    // We'll reformat that into CSV-ready key-value pairs
    // Multi-Select items will be comma-separated
    questions.forEach(function (question) {
      let type = question.get('answer_type');
      let key = question.get('question_key');
      let keyedAnswerValues = question.get('arrKeyedValues');
      let patientAnswers = JSON.parse(patientLog.get('patient_answers'));

      let answer = patientAnswers[key]; //get the patients answer (in key form)

      if (type === "DROPDOWN" || type === "RADIO") {
        answer = keyedAnswerValues[answer]; //the answer is a key. get value from answer key
      }
      if (type === "CHECKBOX") {
        if (answer) {
          let arrChecked = answer.split(",");
          let checkedAnswers = "";
          arrChecked.forEach(function (checkedKey) {
            if (checkedKey) checkedAnswers = checkedAnswers + keyedAnswerValues[checkedKey] + ",";
          });
          answer = checkedAnswers.replace(/,+$/, "");
        }
      }
      if (type === "DATE") {
        answer = formatExportDate(answer);
      }

      exportModel.set(key, answer);
    });

    return exportModel;
  }

  /**
   * All values are string, and dates are stored like, "Wed Dec 12 2018 00:00:00 GMT-0500 (Eastern Standard Time)"
   * Attempt to parse them as dates and return them as a Date object. Otherwise return value as-is.
   */
  function formatExportDate(value) {
    if (!value || value.length < 15) return value;
    let dateValue = new Date(value);
    if ("Invalid Date" == dateValue) return value;
    return dateValue;
  }
});